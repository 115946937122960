import Button from 'components/Forms/Button';
import Text from 'components/Typography/Text';
import IncompleteIcon from 'assets/icons/ic_incomplete.svg?url';

import useNavigation, { PageName } from 'hooks/useNavigation';
import useUserAgent from 'hooks/useUserAgent';
import { postCommandMessage, PostMessageCommandKey } from 'libs/bridge';
import { useRouter } from 'next/router';
import * as styles from './styles';

const ErrorPage = () => {
  const { routerReplace } = useNavigation();
  const router = useRouter();
  const { isMobile } = useUserAgent();
  const onClickConfirmButton = () => {
    if (isMobile) {
      postCommandMessage(PostMessageCommandKey.close);
    } else if (window.history.state.idx > 0) {
      router.back();
    } else {
      routerReplace(PageName.root);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentWarp}>
        <img className={styles.img} src={IncompleteIcon} alt="굿닥 오류" />
        <Text.Head1>서비스 오류가 발생했어요.</Text.Head1>
        <Text.Body1>
          주소가 잘못 입력되었거나 삭제되어
          <br />
          페이지를 찾을 수 없어요
        </Text.Body1>
      </div>
      <Button size="large" styleType="primaryFilled" onClick={onClickConfirmButton}>
        확인했어요
      </Button>
    </div>
  );
};
export default ErrorPage;
