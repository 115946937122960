import { css } from '@emotion/css';
import Color from 'styles/colors';

export const container = css`
  max-width: 36rem !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  min-height: 100vh;
  padding: 40px 16px;
  background-color: ${Color.White};
`;

export const contentWarp = css`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const title = css`
  margin: 16px 0;
`;

export const img = css`
  text-align: center;
  margin-bottom: 16px;
`;
